<template>
    <div>
        <Loading v-if="loadingState" />
        <template v-else>
            <span v-if="noxCriteria.length > 0">
                <b-row
                    class=""
                    v-for="(noxItem, index) in noxCriteria"
                    :key="index"
                >
                    <b-col xl="4" sm="6" class="py-1 position-relative">
                        <v-select
                            :clearable="false"
                            v-model="noxItem.criteriaValue"
                            :options="criteria"
                            label="label"
                            :placeholder="
                                $t('SettingPageCard.placeHolder.chooseCriteria')
                            "
                            class="per-page-selector-criteria d-inline-block"
                            @input="
                                validationComparison('criteria_field', noxItem)
                            "
                        >
                        </v-select>
                        <template v-if="textHelper.length > 0">
                            <feather-icon
                                v-show="index == noxCriteria.length - 1"
                                size="16"
                                v-b-tooltip.hover.v-primary
                                :title="
                                    renderMarkIcon('send_nox_criteria_field')
                                "
                                icon="HelpCircleIcon"
                                class="ml-50 position-absolute"
                                style="top: 0"
                            />
                        </template>
                    </b-col>

                    <b-col xl="3" sm="6" class="py-1">
                        <v-select
                            v-model="noxItem.comparisonValue"
                            :clearable="false"
                            :options="comparisonList(noxItem.criteriaValue)"
                            :placeholder="
                                $t('SettingPageCard.placeHolder.equals')
                            "
                            class="per-page-selector d-inline-block"
                            @input="validationComparison('comparison', noxItem)"
                        >
                        </v-select>
                    </b-col>

                    <b-col xl="3" sm="6" class="py-1">
                        <b-form-group>
                            <b-form-input
                                v-model="noxItem.criteria_value"
                                :placeholder="
                                    $t(
                                        'SettingPageCard.placeHolder.inputCriteria'
                                    )
                                "
                                @input="
                                    editNoxCriteria('criteria_value', noxItem)
                                "
                            />
                        </b-form-group>
                        <template v-if="textHelper.length > 0">
                            <feather-icon
                                v-show="index == noxCriteria.length - 1"
                                size="16"
                                v-b-tooltip.hover.v-primary
                                :title="
                                    renderMarkIcon('send_nox_criteria_value')
                                "
                                icon="HelpCircleIcon"
                                class="ml-50 position-absolute"
                                style="top: 0; right: 0"
                            />
                        </template>
                    </b-col>

                    <b-col xl="2" sm="6" class="py-1">
                        <b-button
                            @click="removeNoxCriteria(index, noxItem.uuid)"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="btn-icon mr-1"
                        >
                            <feather-icon icon="XIcon" />
                        </b-button>
                        <b-button
                            @click="addNoxCriteria(noxItem)"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning"
                            class="btn-icon"
                        >
                            <feather-icon icon="PlusIcon" />
                        </b-button>
                    </b-col>
                </b-row>
            </span>

            <ValidationObserver ref="form" v-else>
                <b-row>
                    <b-col xl="4" sm="6" class="py-1 position-relative">
                        <ValidationProvider
                            #default="{errors}"
                            :name="
                                $t('SettingPageCard.placeHolder.chooseCriteria')
                            "
                            rules="required"
                        >
                            <v-select
                                :clearable="false"
                                v-model="newNox.criteriaValue"
                                :options="criteria"
                                label="label"
                                :placeholder="
                                    $t(
                                        'SettingPageCard.placeHolder.chooseCriteria'
                                    )
                                "
                                class="per-page-selector-criteria d-inline-block"
                                @input="
                                    validationComparison(
                                        'criteria_field',
                                        newNox
                                    )
                                "
                            >
                            </v-select>
                            <template v-if="textHelper.length > 0">
                                <feather-icon
                                    size="16"
                                    v-b-tooltip.hover.v-primary
                                    :title="
                                        renderMarkIcon(
                                            'send_nox_criteria_field'
                                        )
                                    "
                                    icon="HelpCircleIcon"
                                    class="ml-50 position-absolute"
                                    style="top: 0"
                                />
                            </template>

                            <small class="text-danger">
                                {{ errors[0] }}
                            </small>
                        </ValidationProvider>
                    </b-col>
                    <b-col xl="3" sm="6" class="py-1">
                        <ValidationProvider
                            #default="{errors}"
                            :name="$t('SettingPageCard.placeHolder.equals')"
                            rules="required"
                        >
                            <v-select
                                v-model="newNox.comparisonValue"
                                :clearable="false"
                                :options="comparisonList(newNox.criteriaValue)"
                                :placeholder="
                                    $t('SettingPageCard.placeHolder.equals')
                                "
                                class="per-page-selector d-inline-block"
                                @input="
                                    validationComparison('comparison', newNox)
                                "
                            >
                            </v-select>

                            <small class="text-danger">
                                {{ errors[0] }}
                            </small>
                        </ValidationProvider>
                    </b-col>

                    <b-col xl="3" sm="6" class="py-1">
                        <b-form-group>
                            <ValidationProvider
                                #default="{errors}"
                                :name="
                                    $t(
                                        'SettingPageCard.placeHolder.inputCriteria'
                                    )
                                "
                                rules="required"
                            >
                                <b-form-input
                                    v-model="newNox.criteria_value"
                                    :placeholder="
                                        $t(
                                            'SettingPageCard.placeHolder.inputCriteria'
                                        )
                                    "
                                    @input="
                                        editNoxCriteria(
                                            'criteria_value',
                                            newNox
                                        )
                                    "
                                />
                                <template v-if="textHelper.length > 0">
                                    <feather-icon
                                        size="16"
                                        v-b-tooltip.hover.v-primary
                                        :title="
                                            renderMarkIcon(
                                                'send_nox_criteria_value'
                                            )
                                        "
                                        icon="HelpCircleIcon"
                                        class="ml-50 position-absolute"
                                        style="top: 0; right: 0"
                                    />
                                </template>
                                <small class="text-danger">
                                    {{ errors[0] }}
                                </small>
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>

                    <b-col xl="2" sm="6" class="py-1">
                        <b-button
                            @click="validationNewNoxList(newNox)"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning"
                            class="btn-icon"
                        >
                            <feather-icon icon="PlusIcon" />
                        </b-button>
                    </b-col>
                </b-row>
            </ValidationObserver>
        </template>
    </div>
</template>

<script>
import {settingsUtilsMixins} from '@/mixins/settingsUtilsMixins';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {required} from '@validations';
export default {
    props: {
        textHelper: Array,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [settingsUtilsMixins],
    name: 'NoxSendCriteria',
    data() {
        return {
            noxCriteria: [],
            noxListCopy: [],
            noxParamsList: [],
            loadingState: true,
            newNox: {
                criteriaValue: '',
                comparisonValue: '',
                criteria_value: '',
                comparison: '',
            },
            limit: 10,
            required,
        };
    },

    methods: {
        getNoxCriteria() {
            this.$useJwt.getNoxCriteriaList().then(res => {
                const {results} = res.data;
                let noxList = [];
                let noxListCopy = [];

                results.forEach(element => {
                    noxList.push({
                        ...element,
                        criteriaValue: this.renderCriteria(
                            element.criteria_field
                        ),
                        comparisonValue: this.renderComparison(
                            element.comparison
                        ),
                    });
                });
                this.noxCriteria = noxList;
                this.loadingState = false;
            });
        },
        addNoxCriteria(value) {
            if (this.noxCriteria.length >= this.limit) {
                //limitMessage
                this.popup(
                    this.$t('SettingPageCard.limitMessage', {
                        number: this.limit,
                    }),
                    'danger',
                    this.$t('Message.Error'),
                    'AlertTriangleIcon'
                );
            } else {
                let newObject = '';
                if (this.noxCriteria.length > 0) {
                    newObject = {
                        ...value,
                        criteria_field: value.criteriaValue.value,
                    };
                    this.noxCriteria.push(newObject);
                } else {
                    newObject = {
                        ...value,
                        criteria_field: value.criteriaValue.value,
                        comparison: value.comparisonValue.value,
                    };
                }

                this.$useJwt.addNoxCriteriaList(newObject).then(res => {
                    const {data} = res;
                    this.popup(
                        data[this.messageLang],
                        'success',
                        this.$t('Message.Success'),
                        'CheckIcon'
                    );
                    this.getNoxCriteria();
                });
            }
        },
        removeNoxCriteria(index, uuid) {
            this.$useJwt.deleteNoxCriteriaList(uuid).then(res => {
                const {data} = res;

                this.popup(
                    data[this.messageLang],
                    'success',
                    this.$t('Message.Success'),
                    'CheckIcon'
                );

                this.noxCriteria.splice(index, 1);

                if (this.noxCriteria.length == 0) {
                    this.newNox = {
                        criteriaValue: '',
                        comparisonValue: '',
                        criteria_value: '',
                        comparison: '',
                    };
                }
            });
        },
        validationComparison(params, value) {
            let comparisonList = this.comparisonList(value.criteriaValue);

            let checkComparisonAvailable = comparisonList.find(
                element => element.value == value.comparisonValue.value
            );

            if (checkComparisonAvailable == undefined) {
                value.comparisonValue = comparisonList[0];

                if (this.noxCriteria.length > 0)
                    this.editNoxCriteria(params, value);
            } else {
                if (this.noxCriteria.length > 0)
                    this.editNoxCriteria(params, value);
            }
        },
        editNoxCriteria(params, value) {
            let newValue = {
                ...value,
                criteria_field: value.criteriaValue.value,
                comparison: value.comparisonValue.value,
            };
            if (this.noxParamsList.length == 0) {
                this.noxParamsList.push(newValue);
                this.$emit('nox', this.noxParamsList);
            } else {
                let getUuid = this.noxParamsList.find(
                    element => element.uuid == value.uuid
                );
                if (getUuid == undefined) {
                    this.noxParamsList.push(newValue);
                    this.$emit('nox', this.noxParamsList);
                } else {
                    getUuid[params] = newValue[params];
                    this.$emit('nox', this.noxParamsList);
                }
            }
        },

        validationNewNoxList(value) {
            this.$refs.form.validate().then(res => {
                if (res) this.addNoxCriteria(value);
            });
        },
    },

    mounted() {
        this.getNoxCriteria();
    },
};
</script>

<style scoped lang="scss">
.per-page-selector-send-method {
    width: 320px;
}

.per-page-selector-criteria {
    width: 100%;
}
.per-page-selector {
    width: 100%;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>
