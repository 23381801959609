var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadingState)?_c('Loading'):[(_vm.noxCriteria.length > 0)?_c('span',_vm._l((_vm.noxCriteria),function(noxItem,index){return _c('b-row',{key:index},[_c('b-col',{staticClass:"py-1 position-relative",attrs:{"xl":"4","sm":"6"}},[_c('v-select',{staticClass:"per-page-selector-criteria d-inline-block",attrs:{"clearable":false,"options":_vm.criteria,"label":"label","placeholder":_vm.$t('SettingPageCard.placeHolder.chooseCriteria')},on:{"input":function($event){return _vm.validationComparison('criteria_field', noxItem)}},model:{value:(noxItem.criteriaValue),callback:function ($$v) {_vm.$set(noxItem, "criteriaValue", $$v)},expression:"noxItem.criteriaValue"}}),(_vm.textHelper.length > 0)?[_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.noxCriteria.length - 1),expression:"index == noxCriteria.length - 1"},{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"ml-50 position-absolute",staticStyle:{"top":"0"},attrs:{"size":"16","title":_vm.renderMarkIcon('send_nox_criteria_field'),"icon":"HelpCircleIcon"}})]:_vm._e()],2),_c('b-col',{staticClass:"py-1",attrs:{"xl":"3","sm":"6"}},[_c('v-select',{staticClass:"per-page-selector d-inline-block",attrs:{"clearable":false,"options":_vm.comparisonList(noxItem.criteriaValue),"placeholder":_vm.$t('SettingPageCard.placeHolder.equals')},on:{"input":function($event){return _vm.validationComparison('comparison', noxItem)}},model:{value:(noxItem.comparisonValue),callback:function ($$v) {_vm.$set(noxItem, "comparisonValue", $$v)},expression:"noxItem.comparisonValue"}})],1),_c('b-col',{staticClass:"py-1",attrs:{"xl":"3","sm":"6"}},[_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":_vm.$t(
                                    'SettingPageCard.placeHolder.inputCriteria'
                                )},on:{"input":function($event){return _vm.editNoxCriteria('criteria_value', noxItem)}},model:{value:(noxItem.criteria_value),callback:function ($$v) {_vm.$set(noxItem, "criteria_value", $$v)},expression:"noxItem.criteria_value"}})],1),(_vm.textHelper.length > 0)?[_c('feather-icon',{directives:[{name:"show",rawName:"v-show",value:(index == _vm.noxCriteria.length - 1),expression:"index == noxCriteria.length - 1"},{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"ml-50 position-absolute",staticStyle:{"top":"0","right":"0"},attrs:{"size":"16","title":_vm.renderMarkIcon('send_nox_criteria_value'),"icon":"HelpCircleIcon"}})]:_vm._e()],2),_c('b-col',{staticClass:"py-1",attrs:{"xl":"2","sm":"6"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon mr-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.removeNoxCriteria(index, noxItem.uuid)}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.addNoxCriteria(noxItem)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)}),1):_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{staticClass:"py-1 position-relative",attrs:{"xl":"4","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('SettingPageCard.placeHolder.chooseCriteria'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('v-select',{staticClass:"per-page-selector-criteria d-inline-block",attrs:{"clearable":false,"options":_vm.criteria,"label":"label","placeholder":_vm.$t(
                                    'SettingPageCard.placeHolder.chooseCriteria'
                                )},on:{"input":function($event){return _vm.validationComparison(
                                    'criteria_field',
                                    _vm.newNox
                                )}},model:{value:(_vm.newNox.criteriaValue),callback:function ($$v) {_vm.$set(_vm.newNox, "criteriaValue", $$v)},expression:"newNox.criteriaValue"}}),(_vm.textHelper.length > 0)?[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"ml-50 position-absolute",staticStyle:{"top":"0"},attrs:{"size":"16","title":_vm.renderMarkIcon(
                                        'send_nox_criteria_field'
                                    ),"icon":"HelpCircleIcon"}})]:_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{staticClass:"py-1",attrs:{"xl":"3","sm":"6"}},[_c('ValidationProvider',{attrs:{"name":_vm.$t('SettingPageCard.placeHolder.equals'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var errors = ref.errors;
return [_c('v-select',{staticClass:"per-page-selector d-inline-block",attrs:{"clearable":false,"options":_vm.comparisonList(_vm.newNox.criteriaValue),"placeholder":_vm.$t('SettingPageCard.placeHolder.equals')},on:{"input":function($event){return _vm.validationComparison('comparison', _vm.newNox)}},model:{value:(_vm.newNox.comparisonValue),callback:function ($$v) {_vm.$set(_vm.newNox, "comparisonValue", $$v)},expression:"newNox.comparisonValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1),_c('b-col',{staticClass:"py-1",attrs:{"xl":"3","sm":"6"}},[_c('b-form-group',[_c('ValidationProvider',{attrs:{"name":_vm.$t(
                                    'SettingPageCard.placeHolder.inputCriteria'
                                ),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t(
                                        'SettingPageCard.placeHolder.inputCriteria'
                                    )},on:{"input":function($event){return _vm.editNoxCriteria(
                                        'criteria_value',
                                        _vm.newNox
                                    )}},model:{value:(_vm.newNox.criteria_value),callback:function ($$v) {_vm.$set(_vm.newNox, "criteria_value", $$v)},expression:"newNox.criteria_value"}}),(_vm.textHelper.length > 0)?[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"ml-50 position-absolute",staticStyle:{"top":"0","right":"0"},attrs:{"size":"16","title":_vm.renderMarkIcon(
                                            'send_nox_criteria_value'
                                        ),"icon":"HelpCircleIcon"}})]:_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}])})],1)],1),_c('b-col',{staticClass:"py-1",attrs:{"xl":"2","sm":"6"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"btn-icon",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.validationNewNoxList(_vm.newNox)}}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1)],1)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }