import useAppConfig from '@core/app-config/useAppConfig';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
export const settingsUtilsMixins = {
    directives: {
        Ripple,
    },
    components: {
        vSelect,
    },
    data() {
        return {
            config: useAppConfig(),
            equalsSelect: this.$t('SettingPageCard.equals'),
        };
    },
    computed: {
        sendMethods() {
            return this.$t('SettingPageCard.sendMethods');
        },
        criteria() {
            return this.$t('SettingPageCard.criteria');
        },
        textLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swedish_text' : 'english_text';
            } else
                return this.getLang == 'sv' ? 'swedish_text' : 'english_text';
        },
        messageLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        comparisonList(criteriaField) {
            const {value} = criteriaField;

            if (value !== 'total')
                return this.equalsSelect.filter(
                    element => element.value == '=='
                );
            else return this.equalsSelect;
        },
        renderComparison(params) {
            return this.$t('SettingPageCard.equals').find(
                element => element.value == params
            );
        },
        popup(message, variant, title, icon) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                    text: message,
                },
            });
        },

        renderSendMethods(params) {
            return this.$t('SettingPageCard.sendMethods').find(
                element => element.value == params
            );
        },
        renderCriteria(params) {
            return this.$t('SettingPageCard.criteria').find(
                element => element.value == params
            );
        },
        renderMarkIcon(field) {
            let text = this.textHelper.find(element => element.field == field);

            return text[this.textLang];
        },
    },
};
