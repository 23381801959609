<template>
    <div>
        <b-card class="d-flex" v-if="settingsResult.length == 0">
            <Loading />
        </b-card>

        <div v-else>
            <b-card v-for="(data, index) in settingsResult" :key="index">
                <b-card-text class="text-center">
                    <h2>
                        {{ $t('SettingPageCard.title') }}
                    </h2>
                </b-card-text>
                <div class="">
                    <b-card-title>
                        {{ $t('SettingPageCard.bookeepInvoice') }}
                    </b-card-title>
                    <b-card-text class="">
                        <b-form-checkbox
                            v-model="data.auto_bookkeep"
                            class="custom-control-primary"
                            @input="
                                addConfigurationParams(
                                    'auto_bookkeep',
                                    data.auto_bookkeep
                                )
                            "
                        >
                            {{ renderText('auto_bookkeep') }}
                            <feather-icon
                                size="16"
                                v-b-tooltip.hover.v-primary
                                :title="renderText('auto_bookkeep')"
                                icon="HelpCircleIcon"
                                class="mr-50"
                            />
                        </b-form-checkbox>

                        <!-- BookkeeepList -->
                        <Bookkeep
                            @bookeep="addNewBookList($event)"
                            :textHelper="helptext"
                        />
                    </b-card-text>
                </div>

                <div>
                    <b-card-title>
                        {{ $t('SettingPageCard.sendInvoicesDirect') }}
                    </b-card-title>
                    <b-card-text>
                        <b-form-checkbox
                            v-model="data.auto_send_regular"
                            class="custom-control-primary"
                            @input="
                                addConfigurationParams(
                                    'auto_send_regular',
                                    data.auto_send_regular
                                )
                            "
                        >
                            {{ renderText('auto_send_regular') }}
                            <feather-icon
                                size="16"
                                v-b-tooltip.hover.v-primary
                                :title="renderText('auto_send_regular')"
                                icon="HelpCircleIcon"
                                class="mr-50"
                            />
                        </b-form-checkbox>
                    </b-card-text>

                    <b-card-text>
                        <b-form-checkbox
                            v-model="data.use_print_service_for_print"
                            class="custom-control-primary"
                            @input="
                                addConfigurationParams(
                                    'use_print_service_for_print',
                                    data.use_print_service_for_print
                                )
                            "
                        >
                            {{ renderText('use_print_service_for_print') }}
                            <feather-icon
                                size="16"
                                v-b-tooltip.hover.v-primary
                                :title="renderText('use_print_service_for_print')"
                                icon="HelpCircleIcon"
                                class="mr-50"
                            />
                        </b-form-checkbox>
                    </b-card-text>

                    <div class="mt-2">
                        <b-row>
                            <b-col xl="4" sm="6" class="py-1 position-relative">
                                <v-select
                                    :clearable="false"
                                    :options="sendMethods"
                                    v-model="data.regularSendValue"
                                    :placeholder="
                                        $t(
                                            'SettingPageCard.placeHolder.chooceSendMethod'
                                        )
                                    "
                                    class="per-page-selector-send-method d-inline-block"
                                    @input="
                                        addConfigurationParams(
                                            'regular_send_method',
                                            data.regularSendValue
                                        )
                                    "
                                >
                                </v-select>
                                <feather-icon
                                    size="16"
                                    v-b-tooltip.hover.v-primary
                                    :title="renderText('regular_send_method')"
                                    icon="HelpCircleIcon"
                                    class="ml-50 position-absolute"
                                    style="top: 0px"
                                />
                            </b-col>
                        </b-row>

                        <RegularCriteria
                            @regular="addRegularList($event)"
                            :textHelper="helptext"
                        />
                    </div>
                </div>

                <div>
                    <b-card-title>
                        {{ $t('SettingPageCard.sendInvoicesViaFortnox') }}
                    </b-card-title>
                    <b-card-text>
                        <b-form-checkbox
                            v-model="data.auto_send_nox"
                            class="custom-control-primary"
                            @input="
                                addConfigurationParams(
                                    'auto_send_nox',
                                    data.auto_send_nox
                                )
                            "
                        >
                            {{ renderText('auto_send_nox') }}
                            <feather-icon
                                size="16"
                                v-b-tooltip.hover.v-primary
                                :title="renderText('auto_send_nox')"
                                icon="HelpCircleIcon"
                                class="mr-50"
                            />
                        </b-form-checkbox>
                    </b-card-text>

                    <div class="mt-2">
                        <b-row>
                            <b-col
                                xl="4"
                                sm="6"
                                class="d-flex py-1 position-relative"
                            >
                                <v-select
                                    :clearable="false"
                                    :options="sendMethodsNox"
                                    v-model="data.sendNoxValue"
                                    :placeholder="
                                        $t(
                                            'SettingPageCard.placeHolder.chooceSendMethod'
                                        )
                                    "
                                    class="per-page-selector-send-method d-inline-block"
                                    @input="
                                        addConfigurationParams(
                                            'nox_send_method',
                                            data.sendNoxValue
                                        )
                                    "
                                >
                                </v-select>
                                <feather-icon
                                    size="16"
                                    v-b-tooltip.hover.v-primary
                                    :title="renderText('nox_send_method')"
                                    icon="HelpCircleIcon"
                                    class="ml-50 position-absolute"
                                    style="top: 0px; right: -8px"
                                />
                            </b-col>
                        </b-row>

                        <NoxSendCriteria
                            @nox="addNoxList($event)"
                            :textHelper="helptext"
                        />
                    </div>
                </div>

                <div class="">
                    <b-card-title>
                        {{ $t('SettingPageCard.backDaysTime') }}
                    </b-card-title>
                    <b-card-text class="">
                        <div
                            class="d-flex align-items-center"
                            style="gap: 0.5rem"
                        >
                            <p class="mb-0">
                                {{ renderText('days_back_in_time') }}
                            </p>
                            <feather-icon
                                size="16"
                                v-b-tooltip.hover.v-primary
                                :title="renderText('days_back_in_time')"
                                icon="HelpCircleIcon"
                                class="mr-50"
                            />
                        </div>

                        <div class="mt-2">
                            <b-row class="">
                                <b-col xl="3" sm="6" class="py-1">
                                    <validation-observer ref="formDays">
                                        <b-form-group>
                                            <validation-provider
                                                #default="{errors}"
                                                :name="
                                                    $t(
                                                        'SettingPageCard.placeHolder.chooseStartDays'
                                                    )
                                                "
                                                rules="integer|max7:7"
                                            >
                                                <b-form-input
                                                    v-model="
                                                        data.days_back_in_time
                                                    "
                                                    :placeholder="
                                                        $t(
                                                            'SettingPageCard.placeHolder.numberOfDays'
                                                        )
                                                    "
                                                    @input="
                                                        addConfigurationParams(
                                                            'days_back_in_time',
                                                            data.days_back_in_time
                                                        )
                                                    "
                                                />
                                                <small class="text-danger">
                                                    {{ errors[0] }}
                                                </small>
                                            </validation-provider>
                                        </b-form-group>
                                    </validation-observer>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card-text>
                </div>

                <div class="">
                    <b-card-title>
                        {{ $t('SettingPageCard.starDate') }}
                    </b-card-title>
                    <b-card-text class="">
                        <div
                            class="d-flex align-items-center"
                            style="gap: 0.5rem"
                        >
                            <p class="mb-0">
                                {{ renderText('start_date') }}
                            </p>
                            <feather-icon
                                size="16"
                                v-b-tooltip.hover.v-primary
                                :title="renderText('start_date')"
                                icon="HelpCircleIcon"
                                class="mr-50"
                            />
                        </div>

                        <div class="">
                            <b-row class="">
                                <b-col xl="3" sm="6" class="py-1">
                                    <date-picker
                                        v-model="data.start_date"
                                        valueType="format"
                                        prefix-class="xmx"
                                        @input="
                                            changeDate(
                                                'start_date',
                                                data.start_date
                                            )
                                        "
                                    ></date-picker>
                                    <small
                                        v-show="startDateMsg"
                                        class="text-danger"
                                    >
                                        {{
                                            $t('Message.messageStartDateError')
                                        }}
                                    </small>
                                </b-col>
                            </b-row>
                        </div>
                    </b-card-text>
                </div>

                <div class="">
                    <b-card-title>
                        <span class="mr-1">
                            {{ $t('SettingPageCard.upgradeOption') }}
                        </span>
                        <feather-icon
                            size="16"
                            v-b-tooltip.hover.v-primary
                            :title="renderText('upgrade_option')"
                            icon="HelpCircleIcon"
                            class="mr-50"
                        />
                    </b-card-title>
                    <b-card-text class="">
                        <div class="">
                            <div
                                v-for="(upgradeOption, index) in upgradeOptions"
                                :key="index"
                                class="mb-1 d-flex align-content-center"
                            >
                                <b-form-radio
                                    v-model="data.upgrade_option"
                                    :value="upgradeOption.value"
                                    @input="
                                        addConfigurationParams(
                                            'upgrade_option',
                                            data.upgrade_option
                                        )
                                    "
                                >
                                    {{ renderText(upgradeOption.text) }}
                                </b-form-radio>
                                <div class="my-auto" style="margin-left: 5px">
                                    <feather-icon
                                        size="16"
                                        icon="HelpCircleIcon"
                                        v-b-tooltip.hover.v-primary
                                        :title="renderText(upgradeOption.text)"
                                    />
                                </div>
                            </div>
                        </div>
                    </b-card-text>
                </div>

                <div class="">
                    <b-card-title>
                        <span class="mr-1">
                            {{ $t('SettingPageCard.subscriber') }}
                        </span>
                        <feather-icon
                            size="16"
                            icon="HelpCircleIcon"
                            class="mr-50"
                            v-b-tooltip.hover.v-primary
                            :title="renderText('subscription')"
                        />
                    </b-card-title>
                    <b-card-text class="">
                        <SubscriptionToggle />

                        <div class="d-flex justify-content-center py-4">
                            <div style="width: 300px">
                                <b-button
                                    @click="updateConfiguration(data)"
                                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                    variant="primary"
                                    block
                                >
                                    <span v-if="loading">
                                        <b-spinner
                                            small
                                            type="grow"
                                        ></b-spinner>
                                        {{ $t('Message.loading') }}...
                                    </span>

                                    <span v-else>
                                        {{ $t('SettingPageCard.save') }}
                                    </span>
                                </b-button>
                            </div>
                        </div>
                    </b-card-text>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {integer, max7} from '@validations';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {settingsMixins} from '@/mixins/settingsMixins';
import PaymentToggler from '@/@core/components/subscription/PaymentToggler.vue';
import Bookkeep from '@/@core/components/settings/Bookkeep.vue';
import RegularCriteria from '@/@core/components/settings/RegularCriteria.vue';
import NoxSendCriteria from '@/@core/components/settings/NoxSendCriteria.vue';
import SubscriptionToggle from '@/@core/components/settings/SubscriptionToggle.vue';
import {BFormDatepicker} from 'bootstrap-vue';
import DatePicker from 'vue2-datepicker';

export default {
    mixins: [settingsMixins],
    directives: {
        Ripple,
    },
    components: {
        vSelect,
        PaymentToggler,
        Bookkeep,
        RegularCriteria,
        NoxSendCriteria,
        ValidationProvider,
        ValidationObserver,
        BFormDatepicker,
        SubscriptionToggle,
        DatePicker,
    },
    data() {
        return {
            upgradeOptions: [
                {
                    text: 'upgrade_option_auto_upgrade',
                    value: 'auto_upgrade',
                },
                {
                    text: 'upgrade_option_pause_notify',
                    value: 'pause_and_notify',
                },
            ],
            loading: false,
            max7,
            integer,
            configurationParams: {},
            btnDisabled: true,
            openField: false,
            bookeepList: '',
            regularList: '',
            noxList: '',
            startDateMsg: false,
        };
    },
    watch: {},

    methods: {
        changeDate(params, value) {
            let defaultDate = new Date(this.copySettingsResult[0][params]);
            let newDate = new Date(value);
            let days7Ago = new Date(defaultDate.getTime());
            days7Ago.setDate(days7Ago.getDate() - 7);

            if (days7Ago.getTime() > newDate.getTime()) {
                this.startDateMsg = true;
            } else {
                this.startDateMsg = false;
                this.configurationParams[params] = value;
            }
        },
        addConfigurationParams(params, value) {
            if (this.copySettingsResult[0][params] !== value) {
                //Cheking value variable
                this.configurationParams[params] = value.value || value;
            }
        },
        updateConfiguration(value) {
            this.loading = true;

            //Update BookeepList
            if (this.bookeepList.length > 0) {
                this.updateBookList();
            } else if (this.regularList.length > 0) {
                this.updateSendRegularList();
            } else if (this.noxList.length > 0) {
                this.updateNoxCriteria();
            } else if (Object.keys(this.configurationParams).length !== 0) {
                this.$useJwt
                    .updateConfigure(value.uuid, this.configurationParams)
                    .then(res => {
                        const {data} = res;

                        this.popup(
                            data[this.messageLang],
                            'success',
                            this.$t('Message.Success'),
                            'CheckIcon'
                        );

                        this.getConfiguration();
                    })
                    .catch(err => {
                        if (err.response) {
                            console.log(err.response.data.message);

                            let message = JSON.parse(err.response.data.message);
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                        this.btnDisabled = true;
                    });
            } else {
                this.popup(
                    this.$t('Message.warningMsg'),
                    'warning',
                    this.$t('Message.warning'),
                    'AlertTriangleIcon'
                );
                this.loading = false;
            }
        },

        updateBookList() {
            this.bookeepList.forEach(element => {
                this.$useJwt
                    .updateBookKeepList(element.uuid, element)
                    .then(res => {
                        const {data} = res;
                        this.popup(
                            data[this.messageLang],
                            'success',
                            this.$t('Message.Success'),
                            'CheckIcon'
                        );

                        this.bookeepList = [];
                    })
                    .finally(() => (this.loading = false));
            });
        },

        updateSendRegularList() {
            this.regularList.forEach(element => {
                this.$useJwt
                    .updateRegularCriteriaList(element.uuid, element)
                    .then(res => {
                        const {data} = res;
                        this.popup(
                            data[this.messageLang],
                            'success',
                            this.$t('Message.Success'),
                            'CheckIcon'
                        );

                        this.regularList = [];
                    })
                    .finally(() => (this.loading = false));
            });
        },

        updateNoxCriteria() {
            this.noxList.forEach(element => {
                this.$useJwt
                    .updateNoxCriteriaList(element.uuid, element)
                    .then(res => {
                        const {data} = res;
                        this.popup(
                            data[this.messageLang],
                            'success',
                            this.$t('Message.Success'),
                            'CheckIcon'
                        );

                        this.noxList = [];
                    })
                    .finally(() => (this.loading = false));
            });
        },

        addNewBookList(value) {
            this.bookeepList = value;
        },

        addRegularList(value) {
            this.regularList = value;
        },

        addNoxList(value) {
            this.noxList = value;
        },

        popup(message, variant, title, icon) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: title,
                    icon: icon,
                    variant: variant,
                    text: message,
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.per-page-selector-send-method {
    width: 100%;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/datepicker.scss';
</style>
