var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.settingsResult.length == 0)?_c('b-card',{staticClass:"d-flex"},[_c('Loading')],1):_c('div',_vm._l((_vm.settingsResult),function(data,index){return _c('b-card',{key:index},[_c('b-card-text',{staticClass:"text-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('SettingPageCard.title'))+" ")])]),_c('div',{},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('SettingPageCard.bookeepInvoice'))+" ")]),_c('b-card-text',{},[_c('b-form-checkbox',{staticClass:"custom-control-primary",on:{"input":function($event){return _vm.addConfigurationParams(
                                'auto_bookkeep',
                                data.auto_bookkeep
                            )}},model:{value:(data.auto_bookkeep),callback:function ($$v) {_vm.$set(data, "auto_bookkeep", $$v)},expression:"data.auto_bookkeep"}},[_vm._v(" "+_vm._s(_vm.renderText('auto_bookkeep'))+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"mr-50",attrs:{"size":"16","title":_vm.renderText('auto_bookkeep'),"icon":"HelpCircleIcon"}})],1),_c('Bookkeep',{attrs:{"textHelper":_vm.helptext},on:{"bookeep":function($event){return _vm.addNewBookList($event)}}})],1)],1),_c('div',[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('SettingPageCard.sendInvoicesDirect'))+" ")]),_c('b-card-text',[_c('b-form-checkbox',{staticClass:"custom-control-primary",on:{"input":function($event){return _vm.addConfigurationParams(
                                'auto_send_regular',
                                data.auto_send_regular
                            )}},model:{value:(data.auto_send_regular),callback:function ($$v) {_vm.$set(data, "auto_send_regular", $$v)},expression:"data.auto_send_regular"}},[_vm._v(" "+_vm._s(_vm.renderText('auto_send_regular'))+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"mr-50",attrs:{"size":"16","title":_vm.renderText('auto_send_regular'),"icon":"HelpCircleIcon"}})],1)],1),_c('b-card-text',[_c('b-form-checkbox',{staticClass:"custom-control-primary",on:{"input":function($event){return _vm.addConfigurationParams(
                                'use_print_service_for_print',
                                data.use_print_service_for_print
                            )}},model:{value:(data.use_print_service_for_print),callback:function ($$v) {_vm.$set(data, "use_print_service_for_print", $$v)},expression:"data.use_print_service_for_print"}},[_vm._v(" "+_vm._s(_vm.renderText('use_print_service_for_print'))+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"mr-50",attrs:{"size":"16","title":_vm.renderText('use_print_service_for_print'),"icon":"HelpCircleIcon"}})],1)],1),_c('div',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{staticClass:"py-1 position-relative",attrs:{"xl":"4","sm":"6"}},[_c('v-select',{staticClass:"per-page-selector-send-method d-inline-block",attrs:{"clearable":false,"options":_vm.sendMethods,"placeholder":_vm.$t(
                                        'SettingPageCard.placeHolder.chooceSendMethod'
                                    )},on:{"input":function($event){return _vm.addConfigurationParams(
                                        'regular_send_method',
                                        data.regularSendValue
                                    )}},model:{value:(data.regularSendValue),callback:function ($$v) {_vm.$set(data, "regularSendValue", $$v)},expression:"data.regularSendValue"}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"ml-50 position-absolute",staticStyle:{"top":"0px"},attrs:{"size":"16","title":_vm.renderText('regular_send_method'),"icon":"HelpCircleIcon"}})],1)],1),_c('RegularCriteria',{attrs:{"textHelper":_vm.helptext},on:{"regular":function($event){return _vm.addRegularList($event)}}})],1)],1),_c('div',[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('SettingPageCard.sendInvoicesViaFortnox'))+" ")]),_c('b-card-text',[_c('b-form-checkbox',{staticClass:"custom-control-primary",on:{"input":function($event){return _vm.addConfigurationParams(
                                'auto_send_nox',
                                data.auto_send_nox
                            )}},model:{value:(data.auto_send_nox),callback:function ($$v) {_vm.$set(data, "auto_send_nox", $$v)},expression:"data.auto_send_nox"}},[_vm._v(" "+_vm._s(_vm.renderText('auto_send_nox'))+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"mr-50",attrs:{"size":"16","title":_vm.renderText('auto_send_nox'),"icon":"HelpCircleIcon"}})],1)],1),_c('div',{staticClass:"mt-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex py-1 position-relative",attrs:{"xl":"4","sm":"6"}},[_c('v-select',{staticClass:"per-page-selector-send-method d-inline-block",attrs:{"clearable":false,"options":_vm.sendMethodsNox,"placeholder":_vm.$t(
                                        'SettingPageCard.placeHolder.chooceSendMethod'
                                    )},on:{"input":function($event){return _vm.addConfigurationParams(
                                        'nox_send_method',
                                        data.sendNoxValue
                                    )}},model:{value:(data.sendNoxValue),callback:function ($$v) {_vm.$set(data, "sendNoxValue", $$v)},expression:"data.sendNoxValue"}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"ml-50 position-absolute",staticStyle:{"top":"0px","right":"-8px"},attrs:{"size":"16","title":_vm.renderText('nox_send_method'),"icon":"HelpCircleIcon"}})],1)],1),_c('NoxSendCriteria',{attrs:{"textHelper":_vm.helptext},on:{"nox":function($event){return _vm.addNoxList($event)}}})],1)],1),_c('div',{},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('SettingPageCard.backDaysTime'))+" ")]),_c('b-card-text',{},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.5rem"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.renderText('days_back_in_time'))+" ")]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"mr-50",attrs:{"size":"16","title":_vm.renderText('days_back_in_time'),"icon":"HelpCircleIcon"}})],1),_c('div',{staticClass:"mt-2"},[_c('b-row',{},[_c('b-col',{staticClass:"py-1",attrs:{"xl":"3","sm":"6"}},[_c('validation-observer',{ref:"formDays",refInFor:true},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t(
                                                    'SettingPageCard.placeHolder.chooseStartDays'
                                                ),"rules":"integer|max7:7"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t(
                                                        'SettingPageCard.placeHolder.numberOfDays'
                                                    )},on:{"input":function($event){return _vm.addConfigurationParams(
                                                        'days_back_in_time',
                                                        data.days_back_in_time
                                                    )}},model:{value:(
                                                    data.days_back_in_time
                                                ),callback:function ($$v) {_vm.$set(data, "days_back_in_time", $$v)},expression:"\n                                                    data.days_back_in_time\n                                                "}}),_c('small',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1)],1)],1)],1)])],1),_c('div',{},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('SettingPageCard.starDate'))+" ")]),_c('b-card-text',{},[_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"0.5rem"}},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.renderText('start_date'))+" ")]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"mr-50",attrs:{"size":"16","title":_vm.renderText('start_date'),"icon":"HelpCircleIcon"}})],1),_c('div',{},[_c('b-row',{},[_c('b-col',{staticClass:"py-1",attrs:{"xl":"3","sm":"6"}},[_c('date-picker',{attrs:{"valueType":"format","prefix-class":"xmx"},on:{"input":function($event){return _vm.changeDate(
                                            'start_date',
                                            data.start_date
                                        )}},model:{value:(data.start_date),callback:function ($$v) {_vm.$set(data, "start_date", $$v)},expression:"data.start_date"}}),_c('small',{directives:[{name:"show",rawName:"v-show",value:(_vm.startDateMsg),expression:"startDateMsg"}],staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.$t('Message.messageStartDateError'))+" ")])],1)],1)],1)])],1),_c('div',{},[_c('b-card-title',[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.$t('SettingPageCard.upgradeOption'))+" ")]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"mr-50",attrs:{"size":"16","title":_vm.renderText('upgrade_option'),"icon":"HelpCircleIcon"}})],1),_c('b-card-text',{},[_c('div',{},_vm._l((_vm.upgradeOptions),function(upgradeOption,index){return _c('div',{key:index,staticClass:"mb-1 d-flex align-content-center"},[_c('b-form-radio',{attrs:{"value":upgradeOption.value},on:{"input":function($event){return _vm.addConfigurationParams(
                                        'upgrade_option',
                                        data.upgrade_option
                                    )}},model:{value:(data.upgrade_option),callback:function ($$v) {_vm.$set(data, "upgrade_option", $$v)},expression:"data.upgrade_option"}},[_vm._v(" "+_vm._s(_vm.renderText(upgradeOption.text))+" ")]),_c('div',{staticClass:"my-auto",staticStyle:{"margin-left":"5px"}},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"size":"16","icon":"HelpCircleIcon","title":_vm.renderText(upgradeOption.text)}})],1)],1)}),0)])],1),_c('div',{},[_c('b-card-title',[_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm.$t('SettingPageCard.subscriber'))+" ")]),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],staticClass:"mr-50",attrs:{"size":"16","icon":"HelpCircleIcon","title":_vm.renderText('subscription')}})],1),_c('b-card-text',{},[_c('SubscriptionToggle'),_c('div',{staticClass:"d-flex justify-content-center py-4"},[_c('div',{staticStyle:{"width":"300px"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","block":""},on:{"click":function($event){return _vm.updateConfiguration(data)}}},[(_vm.loading)?_c('span',[_c('b-spinner',{attrs:{"small":"","type":"grow"}}),_vm._v(" "+_vm._s(_vm.$t('Message.loading'))+"... ")],1):_c('span',[_vm._v(" "+_vm._s(_vm.$t('SettingPageCard.save'))+" ")])])],1)])],1)],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }