<template>
    <div class="">
        <Loading v-if="loadingState" />
        <template v-else>
            <span v-if="sendRegularList.length > 0">
                <b-row
                    v-for="(sendRegular, index) in sendRegularList"
                    :key="index"
                >
                    <b-col xl="4" sm="6" class="py-1 position-relative">
                        <v-select
                            v-model="sendRegular.criteriaValue"
                            :clearable="false"
                            :options="criteria"
                            :placeholder="
                                $t('SettingPageCard.placeHolder.chooseCriteria')
                            "
                            class="per-page-selector-criteria d-inline-block"
                            @input="
                                validationComparison(
                                    'criteria_field',
                                    sendRegular
                                )
                            "
                        >
                        </v-select>
                        <template v-if="textHelper.length > 0">
                            <feather-icon
                                v-show="index == sendRegularList.length - 1"
                                size="16"
                                v-b-tooltip.hover.v-primary
                                :title="
                                    renderMarkIcon(
                                        'send_regular_criteria_field'
                                    )
                                "
                                icon="HelpCircleIcon"
                                class="ml-50 position-absolute"
                                style="top: 0"
                            />
                        </template>
                    </b-col>
                    <b-col xl="3" sm="6" class="py-1">
                        <v-select
                            v-model="sendRegular.comparisonValue"
                            :clearable="false"
                            :options="comparisonList(sendRegular.criteriaValue)"
                            :placeholder="
                                $t('SettingPageCard.placeHolder.equals')
                            "
                            class="per-page-selector d-inline-block"
                            @input="
                                validationComparison('comparison', sendRegular)
                            "
                        >
                        </v-select>
                    </b-col>

                    <b-col xl="3" sm="6" class="py-1">
                        <b-form-group>
                            <b-form-input
                                v-model="sendRegular.criteria_value"
                                :placeholder="
                                    $t(
                                        'SettingPageCard.placeHolder.inputCriteria'
                                    )
                                "
                                @input="
                                    editRegularCriteria(
                                        'criteria_value',
                                        sendRegular
                                    )
                                "
                            />
                            <template v-if="textHelper.length > 0">
                                <feather-icon
                                    v-show="index == sendRegularList.length - 1"
                                    size="16"
                                    v-b-tooltip.hover.v-primary
                                    :title="
                                        renderMarkIcon(
                                            'send_regular_criteria_value'
                                        )
                                    "
                                    icon="HelpCircleIcon"
                                    class="ml-50 position-absolute"
                                    style="top: -2px; right: 0"
                                />
                            </template>
                        </b-form-group>
                    </b-col>

                    <b-col xl="2" sm="6" class="py-1">
                        <b-button
                            @click="
                                removeRegularCriteria(index, sendRegular.uuid)
                            "
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="danger"
                            class="btn-icon mr-1"
                        >
                            <feather-icon icon="XIcon" />
                        </b-button>

                        <b-button
                            @click="addNewRegularCriteria(sendRegular)"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning"
                            class="btn-icon"
                        >
                            <feather-icon icon="PlusIcon" />
                        </b-button>
                    </b-col>
                </b-row>
            </span>

            <ValidationObserver ref="form" v-else>
                <b-row>
                    <b-col xl="4" sm="6" class="py-1 position-relative">
                        <ValidationProvider
                            #default="{errors}"
                            rules="required"
                            :name="
                                $t('SettingPageCard.placeHolder.chooseCriteria')
                            "
                        >
                            <v-select
                                v-model="newRegularList.criteriaValue"
                                :clearable="false"
                                :options="criteria"
                                :placeholder="
                                    $t(
                                        'SettingPageCard.placeHolder.chooseCriteria'
                                    )
                                "
                                class="per-page-selector-criteria d-inline-block"
                                @input="
                                    validationComparison(
                                        'criteria_field',
                                        newRegularList
                                    )
                                "
                            >
                            </v-select>
                            <template v-if="textHelper.length > 0">
                                <feather-icon
                                    size="16"
                                    v-b-tooltip.hover.v-primary
                                    :title="
                                        renderMarkIcon(
                                            'send_regular_criteria_field'
                                        )
                                    "
                                    icon="HelpCircleIcon"
                                    class="ml-50 position-absolute"
                                    style="top: 0"
                                />
                            </template>
                            <small class="text-danger">
                                {{ errors[0] }}
                            </small>
                        </ValidationProvider>
                    </b-col>
                    <b-col xl="3" sm="6" class="py-1">
                        <ValidationProvider
                            #default="{errors}"
                            rules="required"
                            :name="$t('SettingPageCard.placeHolder.equals')"
                        >
                            <v-select
                                v-model="newRegularList.comparisonValue"
                                :clearable="false"
                                :options="
                                    comparisonList(newRegularList.criteriaValue)
                                "
                                :placeholder="
                                    $t('SettingPageCard.placeHolder.equals')
                                "
                                class="per-page-selector d-inline-block"
                                @input="
                                    validationComparison(
                                        'comparison',
                                        newRegularList
                                    )
                                "
                            >
                            </v-select>
                            <small class="text-danger">
                                {{ errors[0] }}
                            </small>
                        </ValidationProvider>
                    </b-col>

                    <b-col xl="3" sm="6" class="py-1">
                        <b-form-group>
                            <ValidationProvider
                                #default="{errors}"
                                rules="required"
                                :name="
                                    $t(
                                        'SettingPageCard.placeHolder.inputCriteria'
                                    )
                                "
                            >
                                <b-form-input
                                    v-model="newRegularList.criteria_value"
                                    :placeholder="
                                        $t(
                                            'SettingPageCard.placeHolder.inputCriteria'
                                        )
                                    "
                                    @input="
                                        editRegularCriteria(
                                            'criteria_value',
                                            newRegularList
                                        )
                                    "
                                />
                                <template v-if="textHelper.length > 0">
                                    <feather-icon
                                        size="16"
                                        v-b-tooltip.hover.v-primary
                                        :title="
                                            renderMarkIcon(
                                                'send_regular_criteria_value'
                                            )
                                        "
                                        icon="HelpCircleIcon"
                                        class="ml-50 position-absolute"
                                        style="top: -2px; right: 0"
                                    />
                                </template>
                                <small class="text-danger">
                                    {{ errors[0] }}
                                </small>
                            </ValidationProvider>
                        </b-form-group>
                    </b-col>

                    <b-col xl="2" sm="6" class="py-1">
                        <b-button
                            @click="validationRegularList(newRegularList)"
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="warning"
                            class="btn-icon"
                        >
                            <feather-icon icon="PlusIcon" />
                        </b-button>
                    </b-col>
                </b-row>
            </ValidationObserver>
        </template>
    </div>
</template>

<script>
import {settingsUtilsMixins} from '@/mixins/settingsUtilsMixins';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {required} from '@validations';

export default {
    props: {
        textHelper: Array,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [settingsUtilsMixins],
    name: 'RegularCriteria',

    data() {
        return {
            sendRegularList: [],
            sendRegularListCopy: [],
            sendRegularParamsList: [],
            loadingState: true,
            newRegularList: {
                criteriaValue: '',
                comparisonValue: '',
                criteria_value: '',
                comparison: '',
            },
            limit: 10,
            required,
        };
    },

    methods: {
        getRegularCriteria() {
            this.$useJwt.getRegularCriteriaList().then(res => {
                const {results} = res.data;
                let regularList = [];
                let regularListCopy = [];

                results.forEach(element => {
                    regularList.push({
                        ...element,
                        criteriaValue: this.renderCriteria(
                            element.criteria_field
                        ),
                        comparisonValue: this.renderComparison(
                            element.comparison
                        ),
                    });

                    regularListCopy.push({
                        ...element,
                        criteriaValue: this.renderCriteria(
                            element.criteria_field
                        ),
                        comparisonValue: this.renderComparison(
                            element.comparison
                        ),
                    });
                });

                this.sendRegularList = regularList;
                this.sendRegularListCopy = regularListCopy;
                this.loadingState = false;
            });
        },

        addNewRegularCriteria(value) {
            if (this.sendRegularList.length >= this.limit) {
                //limitMessage
                this.popup(
                    this.$t('SettingPageCard.limitMessage', {
                        number: this.limit,
                    }),
                    'danger',
                    this.$t('Message.Error'),
                    'AlertTriangleIcon'
                );
            } else {
                let newObject = '';
                if (this.sendRegularList.length > 0) {
                    newObject = {
                        ...value,
                        criteria_field: value.criteriaValue.value,
                    };
                    this.sendRegularList.push(newObject);
                } else {
                    newObject = {
                        ...value,
                        criteria_field: value.criteriaValue.value,
                        comparison: value.comparisonValue.value,
                    };
                }

                this.$useJwt.addRegularCriteriaList(newObject).then(res => {
                    const {data} = res;
                    this.popup(
                        data[this.messageLang],
                        'success',
                        this.$t('Message.Success'),
                        'CheckIcon'
                    );

                    this.getRegularCriteria();
                });
            }
        },
        removeRegularCriteria(index, uuid) {
            this.$useJwt.deleteRegularCriteriaList(uuid).then(res => {
                const {data} = res;

                this.popup(
                    data[this.messageLang],
                    'success',
                    this.$t('Message.Success'),
                    'CheckIcon'
                );

                this.sendRegularList.splice(index, 1);

                if (this.sendRegularList.length == 0) {
                    this.newRegularList = {
                        criteriaValue: '',
                        comparisonValue: '',
                        criteria_value: '',
                        comparison: '',
                    };
                }
            });
        },
        validationComparison(params, value) {
            let comparisonList = this.comparisonList(value.criteriaValue);

            let checkComparisonAvailable = comparisonList.find(
                element => element.value == value.comparisonValue.value
            );

            if (checkComparisonAvailable == undefined) {
                value.comparisonValue = comparisonList[0];

                if (this.sendRegularList.length > 0)
                    this.editRegularCriteria(params, value);
            } else {
                if (this.sendRegularList.length > 0)
                    this.editRegularCriteria(params, value);
            }
        },
        editRegularCriteria(params, value) {
            let newValue = {
                ...value,
                criteria_field: value.criteriaValue.value,
                comparison: value.comparisonValue.value,
            };

            if (this.sendRegularParamsList.length == 0) {
                this.sendRegularParamsList.push(newValue);
                this.$emit('regular', this.sendRegularParamsList);
            } else {
                let getUuid = this.sendRegularParamsList.find(
                    element => element.uuid == value.uuid
                );
                if (getUuid == undefined) {
                    this.sendRegularParamsList.push(newValue);
                    this.$emit('regular', this.sendRegularParamsList);
                } else {
                    getUuid[params] = newValue[params];
                    this.$emit('regular', this.sendRegularParamsList);
                }
            }
        },
        validationRegularList(value) {
            this.$refs.form.validate().then(res => {
                if (res) this.addNewRegularCriteria(value);
            });
        },
    },
    async mounted() {
        this.getRegularCriteria();
    },
};
</script>

<style scoped lang="scss">
.per-page-selector-send-method {
    width: 320px;
}

.per-page-selector-criteria {
    width: 100%;
}
.per-page-selector {
    width: 100%;
}

.invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
        flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
        width: 100px;
    }
}
</style>
