import useAppConfig from '@core/app-config/useAppConfig';
export const settingsMixins = {
    data() {
        return {
            settingsResult: [],
            helptext: '',
            copySettingsResult: [],
            config: useAppConfig(),
        };
    },
    computed: {
        sendMethods() {
            return this.$t('SettingPageCard.sendMethods');
        },
        sendMethodsNox() {
            return this.$t('SettingPageCard.sendMethodsNox');
        },
        messageLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'message' : 'eng_message';
        },
        textLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swedish_text' : 'english_text';
            } else
                return this.getLang == 'sv' ? 'swedish_text' : 'english_text';
        },
        getLang() {
            return this.config.lang.value;
        },
    },
    methods: {
        async getListHelpText() {
            await this.$useJwt
                .listHelpText({params: {dashboard_page: 'configuration'}})
                .then(res => {
                    const {results} = res.data;
                    this.helptext = results;
                });
        },
        renderCriteria(params) {
            return this.$t('SettingPageCard.criteria').find(
                element => element.value == params
            );
        },

        renderSendMethods(params) {
            return this.$t('SettingPageCard.sendMethods').find(
                element => element.value == params
            );
        },

        renderNoxMethods(params) {
            return this.$t('SettingPageCard.sendMethodsNox').find(
                element => element.value == params
            );
        },

        renderText(field) {
            let text = this.helptext.find(element => element.field == field);
            return text[this.textLang];
        },

        getConfiguration() {
            this.$useJwt.configure().then(res => {
                const {results} = res.data;

                results.forEach(element => {
                    this.settingsResult = [
                        {
                            ...element,
                            send_regular_criteria:
                                element.send_regular_criteria.map(criteria => {
                                    return {
                                        ...criteria,
                                        criteriaValue: this.renderCriteria(
                                            criteria.criteria_field
                                        ),
                                    };
                                }),
                            send_nox_criteria: element.send_nox_criteria.map(
                                criteria => {
                                    return {
                                        ...criteria,
                                        criteriaValue: this.renderCriteria(
                                            criteria.criteria_field
                                        ),
                                    };
                                }
                            ),
                            regularSendValue: this.renderSendMethods(
                                element.regular_send_method
                            ),
                            sendNoxValue: this.renderNoxMethods(
                                element.nox_send_method
                            ),
                        },
                    ];

                    this.copySettingsResult = [{...element}];
                });
            });
        },
    },
    async mounted() {
        await this.getListHelpText();

        this.getConfiguration();
    },
};
