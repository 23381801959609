<template>
    <div>
        <Loading v-if="subscriptionResult.length == 0" />
        <span v-else>
            <div
                v-for="(data, index) in subscriptionResult"
                :key="index"
                class="d-flex mt-2"
                style="gap: 0.5rem"
            >
                <p class="mb-0 font-weight-bold">
                    {{ $t('SettingPageCard.active') }}
                </p>
                <div
                    class="switch switch-rotate position-relative mx-2"
                    style="width: 40px"
                >
                    <div
                        class="position-absolute"
                        style="top: 4px; right: -3px"
                    >
                        <switches
                            v-model="data.active"
                            type-bold="false"
                            theme="custom"
                            color="green"
                            :emit-on-mount="false"
                            @input="update"
                        ></switches>
                    </div>
                </div>

                <p class="mb-0 font-weight-bold">
                    {{ $t('SettingPageCard.inactive') }}
                </p>
            </div>
        </span>
    </div>
</template>

<script>
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import Switches from 'vue-switches';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import useAppConfig from '@core/app-config/useAppConfig';

export default {
    name: 'SubscriptionToggle',
    mixins: [getSubscribersMixins],
    components: {
        Switches,
        ToastificationContent,
    },

    data() {
        return {
            subscriptionResult: Array,
            config: useAppConfig(),
        };
    },

    computed: {
        messageLang() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        update() {
            const result = this.subscriptionResult[0];

            this.$useJwt
                .updateSubscription(result.uuid, {active: result.active})
                .then(res => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t('Message.Success'),
                            icon: 'CheckIcon',
                            variant: 'success',
                            text: res.data[this.messageLang],
                        },
                    });
                });
        },
    },

    async mounted() {
        const {data} = await this.getSubscriptions();

        this.subscriptionResult = data.data;
    },
};
</script>

<style scoped>
.switch-rotate {
    transform: rotate(180deg);
}
</style>
