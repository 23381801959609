<template>
    <div class="d-flex mt-2" style="gap: 0.5rem">
        <p class="mb-0 px-1 font-weight-bold" :style="fontSize">
            {{ $t('SubscriptionCard.annually') }}
        </p>

        <div class="">
            <switches
                v-model="monthly"
                type-bold="false"
                theme="custom"
                color="green"
                :emit-on-mount="false"
                @input="changePlanPayment"
            ></switches>
        </div>
        <p class="mb-0 px-1 font-weight-bold" :style="fontSize">
            {{ $t('SubscriptionCard.monthly') }}
        </p>
    </div>
</template>

<script>
import Switches from 'vue-switches';
import Ripple from 'vue-ripple-directive';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import {getSubscribersMixins} from '@/mixins/getSubscribersMixins';
import useAppConfig from '@core/app-config/useAppConfig';
export default {
    name: 'PaymentToggler',
    mixins: [getSubscribersMixins],
    props: {
        fontSize: Object,
    },
    directives: {
        Ripple,
    },
    components: {
        Switches,
    },

    data() {
        return {
            monthly: '',
            config: useAppConfig(),
        };
    },
    computed: {
        message() {
            if (this.getLang == '') {
                return this.swedishLang ? 'swe_message' : 'eng_message';
            } else return this.getLang == 'sv' ? 'swe_message' : 'eng_message';
        },
        getLang() {
            return this.config.lang.value;
        },
    },

    methods: {
        async changePlanPayment(value) {
            this.$emit('monthly', {monthly: value, paymentStatusChange: true});
        },
    },

    async mounted() {
        const getPlanName = await this.getPaymentPlan();

        getPlanName[0] == 'Yearly'
            ? (this.monthly = false)
            : (this.monthly = true);

        this.$emit('monthly', {monthly: this.monthly});
    },
};
</script>

<style></style>
